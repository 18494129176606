import { ISettingContainerBaseProps } from "src/components/Settings/Setting/SettingContainer"
import { SettingStatic } from "src/components/Settings/Setting/SettingStatic"
import { spacing } from "src/ui/spacing"

export function ParadiseSettingStatic({
  title,
  value,
  ...props
}: ISettingContainerBaseProps & { value: React.ReactNode }) {
  return (
    <SettingStatic
      title={title}
      value={value}
      titleProps={{
        type: "nano",
        color: "secondary",
      }}
      displayValueProps={{
        type: "bodyS",
        color: "primary",
      }}
      gap={spacing.XS2}
      {...props}
    />
  )
}
